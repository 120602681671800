import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import {COLORS} from '../../../../utils/constants';
import {DataGrid} from '@mui/x-data-grid';

interface IEntrepreneurialOrientationProps {
    baseline: any[];
    final: any[];
}

const baselineKeys = {
    riskTaking: [
        'entr_orient_risk_t0_entr_orient_a_t0',
        'entr_orient_risk_t0_entr_orient_b_t0',
        'entr_orient_risk_t0_entr_orient_c_t0',
    ],
    innovativeness: [
        'entr_orient_inn_t0_entr_orient_d_t0',
        'entr_orient_inn_t0_entr_orient_e_t0',
        'entr_orient_inn_t0_entr_orient_f_t0',
    ],
    proactiveness: [
        'entr_orient_pro_t0_entr_orient_g_t0',
        'entr_orient_pro_t0_entr_orient_h_t0',
        'entr_orient_pro_t0_entr_orient_i_t0',
    ],
    competitiveAggressiveness: [
        'entr_orient_comp_t0_entr_orient_l_t0',
        'entr_orient_comp_t0_entr_orient_m_t0',
        'entr_orient_comp_t0_entr_orient_n_t0',
    ],
    autonomy: [
        'entr_orient_aut_t0_entr_orient_o_t0',
        'entr_orient_aut_t0_entr_orient_p_t0',
        'entr_orient_aut_t0_entr_orient_q_t0',
        'entr_orient_aut_t0_entr_orient_r_t0',
        'entr_orient_aut_t0_entr_orient_s_t0',
        'entr_orient_aut_t0_entr_orient_t_t0',
    ],
};

const finalKeys = {
    riskTaking: [
        'entr_orient_risk_t1_entr_orient_a_t1',
        'entr_orient_risk_t1_entr_orient_b_t1',
        'entr_orient_risk_t1_entr_orient_c_t1',
    ],
    innovativeness: [
        'entr_orient_inn_t1_entr_orient_d_t1',
        'entr_orient_inn_t1_entr_orient_e_t1',
        'entr_orient_inn_t1_entr_orient_f_t1',
    ],
    proactiveness: [
        'entr_orient_proact_t1_entr_orient_g_t1',
        'entr_orient_proact_t1_entr_orient_h_t1',
        'entr_orient_proact_t1_entr_orient_i_t1',
    ],
    competitiveAggressiveness: [
        'entr_orient_compet_t1_entr_orient_l_t1',
        'entr_orient_compet_t1_entr_orient_m_t1',
        'entr_orient_compet_t1_entr_orient_n_t1',
    ],
    autonomy: [
        'entr_orient_aut_t1_entr_orient_o_t1',
        'entr_orient_aut_t1_entr_orient_p_t1',
        'entr_orient_aut_t1_entr_orient_q_t1',
        'entr_orient_aut_t1_entr_orient_r_t1',
        'entr_orient_aut_t1_entr_orient_s_t1',
        'entr_orient_aut_t1_entr_orient_t_t1',
    ],
};

const EntrepreneursIncrease = ({baseline, final}: IEntrepreneurialOrientationProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    const calculateSubmissions = () => {
        const baselineData = {
            riskTaking: 0,
            innovativeness: 0,
            proactiveness: 0,
            competitiveAggressiveness: 0,
            autonomy: 0,
        };

        const finalData = {
            riskTaking: 0,
            innovativeness: 0,
            proactiveness: 0,
            competitiveAggressiveness: 0,
            autonomy: 0,
        };

        const calcSum = (keys: string[], submission: any) => {
            const tempKeys = keys.map((key) => Number(submission[key]));
            return (tempKeys.reduce((partialSum, a) => partialSum + a, 0) * 1.0) / tempKeys.length;
        };

        const filteredBaseline = baseline.filter(
            (submission: any) => submission.entr_orient_risk_t0_entr_orient_a_t0 !== null
        );

        const filteredFinal = final.filter(
            (submission: any) => submission.entr_orient_risk_t1_entr_orient_a_t1 !== null
        );

        filteredBaseline.forEach((submission: any) => {
            baselineData.riskTaking += calcSum(baselineKeys.riskTaking, submission) / filteredBaseline.length;
            baselineData.innovativeness += calcSum(baselineKeys.innovativeness, submission) / filteredBaseline.length;
            baselineData.proactiveness += calcSum(baselineKeys.proactiveness, submission) / filteredBaseline.length;
            baselineData.competitiveAggressiveness +=
                calcSum(baselineKeys.competitiveAggressiveness, submission) / filteredBaseline.length;
            baselineData.autonomy += calcSum(baselineKeys.autonomy, submission) / filteredBaseline.length;
        });

        filteredFinal.forEach((submission: any) => {
            finalData.riskTaking += calcSum(finalKeys.riskTaking, submission) / filteredFinal.length;
            finalData.innovativeness += calcSum(finalKeys.innovativeness, submission) / filteredFinal.length;
            finalData.proactiveness += calcSum(finalKeys.proactiveness, submission) / filteredFinal.length;
            finalData.competitiveAggressiveness +=
                calcSum(finalKeys.competitiveAggressiveness, submission) / filteredFinal.length;
            finalData.autonomy += calcSum(finalKeys.autonomy, submission) / filteredFinal.length;
        });

        setColumns([
            {field: 'name', headerName: 'Mindset', width: 200},
            {field: 'y', headerName: 'Improvement', width: 200},
        ]);
        setRows([
            {
                id: 1,
                name: 'RISK-TAKING',
                y: ((finalData.riskTaking * 100) / baselineData.riskTaking - 100).toFixed(1) + ' %',
            },
            {
                id: 2,
                name: 'INNOVATIVENESS',
                y: ((finalData.innovativeness * 100) / baselineData.innovativeness - 100).toFixed(1) + ' %',
            },
            {
                id: 3,
                name: 'PROACTIVENESS',
                y: ((finalData.proactiveness * 100) / baselineData.proactiveness - 100).toFixed(1) + ' %',
            },
            {
                id: 4,
                name: 'COMPETITIVE AGGRESSIVENESS',
                y:
                    (
                        (finalData.competitiveAggressiveness * 100) / baselineData.competitiveAggressiveness -
                        100
                    ).toFixed(1) + ' %',
            },
            {
                id: 5,
                name: 'AUTONOMY',
                y: ((finalData.autonomy * 100) / baselineData.autonomy - 100).toFixed(1) + ' %',
            },
        ]);

        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            title: {
                align: 'left',
                text: '',
            },
            xAxis: {
                categories: [
                    'RISK-TAKING',
                    'INNOVATIVENESS',
                    'PROACTIVENESS',
                    'COMPETITIVE AGGRESSIVENESS',
                    'AUTONOMY',
                ],
            },
            legend: {
                verticalAlign: 'top',
            },
            series: [
                {
                    type: 'line',
                    name: 'BASELINE',
                    data: Object.values(baselineData).map((data) => Number(data.toFixed(2))),
                    color: COLORS.blue,
                },
                {
                    type: 'line',
                    name: 'FINAL',
                    data: Object.values(finalData).map((data) => Number(data.toFixed(2))),
                    color: COLORS.yellow,
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                <Typography variant='h6'>Level of increase in entrepreneurial mindset</Typography>
                <br />
                <div className='row'>
                    <div>
                        {options && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                            />
                        )}
                    </div>

                    <div>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            hideFooterPagination={true}
                            hideFooter={true}
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default EntrepreneursIncrease;
