import {Card, CardContent, Typography} from '@mui/material';
import CareerProfile from './CareerProfile';
import ActiveEntrepeneurs from './ActiveEntrepeneurs';
import TypeOfSector from './TypeOfSector';
import AgrifoodSector from './AgrifoodSector';
import FoodProcessingSector from './FoodProcessingSector';
import EnrolledPerCountry from './EnrolledPerCountry';
import EducationalBackground from './EducationalBackground';
import EnrolledItem from '../base/EnrolledItem';

interface IOutputProps {
    entrepeneurs: number;
    women: number;
    youngEntrepeneurs: number;
    baseline: any[];
    editions: number;
    activeEntrepeneurs: {
        active: number;
        not_active: number;
    };
    fields: any[];
}

const Output = ({entrepeneurs, women, youngEntrepeneurs, baseline, activeEntrepeneurs, editions, fields}: IOutputProps) => {
    const educationalBackgroundField = fields.find((field) => field.key === 'educational-background');

    return (
        <>
            <Card>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            value={editions.toFixed()}
                            text='Number of MBA editions'
                        />
                    </div>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            value={entrepeneurs.toFixed()}
                            text='Numbers of entrepreneurs enrolled'
                        />
                    </div>
                </CardContent>
            </Card>
            <br />
            <EnrolledPerCountry submissions={baseline} />
            <br />

            <Card>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            value={((women / entrepeneurs) * 100).toFixed(2)}
                            text='% of women enrolled'
                        />
                    </div>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            value={((youngEntrepeneurs / entrepeneurs) * 100).toFixed(2)}
                            text='% of under 30 enrolled'
                        />
                    </div>
                </CardContent>
            </Card>
            <br />
            <EducationalBackground field={educationalBackgroundField} submissions={baseline} />
            <br />
            <Typography variant='h6'>Program graduates</Typography>
            <br />
            <ActiveEntrepeneurs activeEntrepeneurs={activeEntrepeneurs} />
            <br />
            <div className='row'>
                <CareerProfile submissions={baseline} />
                <TypeOfSector submissions={baseline} />
            </div>
            <br />
            <AgrifoodSector submissions={baseline} />
            <br />
            <FoodProcessingSector submissions={baseline} />
        </>
    );
};

export default Output;
