import * as React from 'react';
import {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Download} from '@mui/icons-material';
import Link from '@mui/material/Link';
import {download} from '../../../utils/download';
import CustomTable from '../../../shared/ApiTable';

interface INumeroValutatoreProps {
    submissions: {
        uoa_id: number;
        uoa_parent_id: number;
        uoa_name: number;
        uoa_custom_field_ente_proponente_51: string;
        validation_status: string;
        proj_places_0_proj_partner_name: string | null;
        proj_places_1_proj_partner_name: string | null;
        proj_partners_0_proj_partner_name: string | null;
        proj_partners_1_proj_partner_name: string | null;
        proj_partners_2_proj_partner_name: string | null;
        proj_duration_months: number;
        proj_budget_short_co_finanziamento_risorse_proprie_e_degli_eventuali_cofinanziatori_euro_proj_budget_amount: number;
        proj_budget_short_contributo_richiesto_a_fcdp_parte_del_budget_euro_proj_budget_amount: number;
        uoa_custom_field_ente_proponente_51_id_value: number[];
    }[];
    anagraficaSubmissions: {
        uoa_id: number;
        uoa_name: number;
        user_name: number;
        org_website_url: string;
        org_type: string;
    }[];
    meta: {
        current_page: number;
        total: number;
        per_page: number;
    };
    statuses: any[];
    containerId: string | null;
    uoas: any[];
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    getAllSurveys: () => Promise<any[]>;
    getAllAnagraficaSurvey: (ids: any[]) => Promise<any[]>;
}

const Table = ({
    submissions,
    uoas,
    statuses,
    anagraficaSubmissions,
    meta,
    containerId,
    handleChangePage,
    handleChangeRowsPerPage,
    getAllSurveys,
    getAllAnagraficaSurvey,
}: INumeroValutatoreProps) => {
    const columns: string[] = [
        'Bando',
        'Ente proponente',
        'Status anagrafica',
        'Titolo progetto',
        'Status proposta progettuale',
        'Responsabile',
        'Email responsabile',
        "Natura dell'Ente",
    ];
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getLinkFromSubmission = (submission: any) => {
        if (!submission) return '';

        const {uoa_id, survey_id, user_id, checkpoint_id} = submission;
        const link =
            process.env.REACT_APP_MAIN_URL +
            `containers/${containerId}/uoa?uoa_id=${uoa_id}&tab=surveys&survey_id=${survey_id}&user_id=${user_id}&checkpoint_id=${checkpoint_id}`;

        return (
            <Link
                href={link}
                target='_blank'
            >
                {submission.uoa_name}
            </Link>
        );
    };

    const processRows = (submissions: any[], anagraficaSubmissions: any[]) => {
        const rows: {}[] = [];

        submissions.forEach((submission) => {
            const anagrafica = anagraficaSubmissions.find((anagraficaSubmission) =>
                submission.uoa_custom_field_ente_proponente_51_id_value.includes(anagraficaSubmission.uoa_id)
            );
            const anagraficaLink = getLinkFromSubmission(anagrafica);
            const progettoLink = getLinkFromSubmission(submission);

            rows.push([
                getBando(submission.uoa_parent_id),
                anagraficaLink,
                anagrafica ? getStatusAnagrafica(anagrafica.validation_status) : '',
                progettoLink,
                getStatusPropostaProgettuale(submission.validation_status),
                anagrafica?.user_name,
                anagrafica?.user_email,
                anagrafica?.org_type,
            ]);
        });

        return rows;
    };

    const getBando = (id: any) => {
        const uoa = uoas.find((uoa: {id: number}) => uoa.id === id);

        if (uoa) {
            return uoa.name;
        }

        return '';
    };

    const getStatusAnagrafica = (status: any) => {
        const validationStatus = statuses.find((validationStatus: {id: string}) => validationStatus.id === status);

        if (validationStatus) {
            return validationStatus.name;
        }

        return status;
    };

    const getStatusPropostaProgettuale = (status: any) => {
        const validationStatus = statuses.find((validationStatus: {id: string}) => validationStatus.id === status);

        if (validationStatus) {
            return validationStatus.name;
        }

        return status;
    };

    const handleDownload = async () => {
        setLoading(true);
        const data = await getAllSurveys();
        const anagraficaData = await getAllAnagraficaSurvey(
            data.map((submission: any) => submission.uoa_custom_field_ente_proponente_51_id_value)
        );
        const rows = processRows(data, anagraficaData);

        download([columns, ...rows], 'Overview di tutti i progetti');
        setLoading(false);
    };

    useEffect(() => {
        setRows(processRows(submissions, anagraficaSubmissions));
    }, []);

    return (
        <>
            <div className='content-title'>
                <Typography variant='h5'>Overview di tutti i progetti</Typography>
                {!loading && (
                    <IconButton onClick={handleDownload}>
                        <Download fontSize='small' />
                    </IconButton>
                )}
                {loading && <>...loading</>}
            </div>
            {rows.length > 0 && (
                <CustomTable
                    columns={columns}
                    rows={rows}
                    meta={meta}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    numberRows={[6, 12, 13]}
                />
            )}
        </>
    );
};

export default Table;
