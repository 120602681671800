import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {COLORS} from '../../../../utils/constants';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IActiveEntrepeneursProps {
    activeEntrepeneurs: {
        active: number;
        not_active: number;
    };
}

const ActiveEntrepeneurs = ({activeEntrepeneurs}: IActiveEntrepeneursProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = [
            {
                name: 'Successfully concluded',
                y: activeEntrepeneurs.active,
                color: COLORS.blue,
            },
            {
                name: 'Dropouts',
                y: activeEntrepeneurs.not_active,
                color: COLORS.yellow,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(
            '% of participants that successfully concluded the MBA and dropouts',
            data
        );

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default ActiveEntrepeneurs;
