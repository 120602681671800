import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {debounce} from '@mui/material/utils';
import {sha256} from 'js-sha256';
import Loader from '../../shared/Loader';
import CustomSelect from '../../shared/CustomSelect';
import apiCall, {setUserToken} from '../../utils/api';
import Table from './Table';
import NoResults from '../../shared/NoResults';
import {filterStatuses} from '../../utils/filterStatuses';

import './styles.scss';

const CdpProposalGrid = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const propostaProgettuale = searchParams.get('proposta_progettuale');
    const propostaDispersione = searchParams.get('proposta_dispersione');
    const propostaTopDown = searchParams.get('proposta_top_down');
    const propostaEcosistemiCulturali = searchParams.get('proposta_ecosistemi_culturali');
    const propostaDispersioneScolastica = searchParams.get('proposta_dispersione_scolastica');
    const propostaRicerca = searchParams.get('proposta_ricerca');
    const enteProponente = searchParams.get('ente_proponente');
    const titoloProgetto = searchParams.get('titolo_progetto');
    const anagrafica = searchParams.get('anagrafica');
    const typeId = searchParams.get('type_id');
    const containerId = searchParams.get('container_id');
    const hashKey = searchParams.get('key');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_CDP || '');

    setUserToken(searchParams.get('token') || '');

    const [loading, setLoading] = useState(true);
    const [uoas, setUoas] = useState([]);
    const [statuses, setStatuses] = useState<{ id: string, name: string }[]>([]);
    const [submissions, setSubmissions] = useState([]);
    const [anagraficaSubmissions, setAnagraficaSubmissions] = useState([]);
    const [selectedUoa, setSelectedUoa] = useState<string>('All');
    const [selectedEnte, setSelectedEnte] = useState<string>('All');
    const [enteOptions, setEnteOptions] = useState([]);
    const [enteOptionsLoading, setEnteOptionsLoading] = useState(false);
    const [selectedProgetto, setSelectedProgetto] = useState<string>('All');
    const [progettoOptions, setProgettoOptions] = useState([]);
    const [progettoOptionsLoading, setProgettoOptionsLoading] = useState(false);
    const [selectedStatusAnagrafica, setSelectedStatusAnagrafica] = useState<string>('All');
    const [selectedStatusPropostaProgettuale, setSelectedStatusPropostaProgettuale] = useState<string>('All');
    const [isReady, setIsReady] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [meta, setMeta] = useState<{
        current_page: number;
        total: number;
        per_page: number;
    }>({
        current_page: 1,
        total: 0,
        per_page: 10,
    });

    const getUoaOptions = async (search: string = 'All', fieldId: string | null, idKey: 'id' | 'object_id') => {
        if (search === 'All' || !fieldId) {
            return [];
        }

        const response = await apiCall({
            url: 'field/values',
            params: {
                searched: {field_id: fieldId},
                search,
            },
        });

        if (response.hasOwnProperty('data')) {
            return response.data.data.map((item: {id: string; name: string; object_id: string}) => ({
                name: item.name,
                id: item[idKey],
            }));
        }
    };

    const getEnteOptions = async (search: string = 'All') => {
        setEnteOptionsLoading(true);
        setEnteOptions(await getUoaOptions(search, enteProponente, 'id'));
        setEnteOptionsLoading(false);
    };

    const getProgettoOptions = async (search: string = 'All') => {
        setProgettoOptionsLoading(true);
        setProgettoOptions(await getUoaOptions(search, titoloProgetto, 'object_id'));
        setProgettoOptionsLoading(false);
    };

    const handeEnteAutocompleteInputChanged = React.useMemo(
        () =>
            debounce((event: any, value: string) => {
                getEnteOptions(value);
            }, 300),
        []
    );

    const handeProgettoAutocompleteInputChanged = React.useMemo(
        () =>
            debounce((event: any, value: string) => {
                getProgettoOptions(value);
            }, 300),
        []
    );

    const getFilterData = async () => {
        if (containerId && anagrafica && typeId && hashKey === hashKeyLocal) {
            const requests = [
                await apiCall({
                    url: `containers/${container}/uoa/search`,
                    params: {
                        container_id: containerId,
                        pagination: 'false',
                        type_id: typeId,
                    },
                }),
                await apiCall({
                    url: `containers/${container}/statuses`,
                    params: {
                        container_id: containerId,
                    },
                }),
            ];

            const responses = await Promise.all(requests);

            if (responses.length > 0 && responses[0].data.hasOwnProperty('data')) {
                setUoas(
                    responses[0].data.data.map((item: {id: number; title: string}) => ({
                        name: item.title,
                        id: item.id,
                    }))
                );
            }

            if (responses.length > 1 && responses[1].data.hasOwnProperty('data')) {
                setStatuses(
                    filterStatuses(responses[1].data.data).map((item: {key: string; name: string}) => ({
                        name: item.name,
                        id: item.key,
                    }))
                );
            }
        }
    };

    const getSubmission = async (rowsPerPage: number, page: number): Promise<any[]> => {
        const requests = [
            await apiCall({
                url: `containers/${container}/submissions/container`,
                params: {
                    container_id: containerId,
                    survey_ids: [
                        propostaProgettuale,
                        propostaDispersione,
                        propostaTopDown,
                        propostaRicerca,
                        propostaEcosistemiCulturali,
                        propostaDispersioneScolastica,
                    ],
                    completed: 1,
                    pagination: 'true',
                    additional_data_ids: 'true',
                    per_page: rowsPerPage,
                    page: page,
                    ...(selectedUoa !== 'All' && {uoa_parent_id: selectedUoa}),
                    ...(selectedStatusPropostaProgettuale !== 'All' && {
                        validation_status: selectedStatusPropostaProgettuale,
                    }),
                    ...(selectedStatusAnagrafica !== 'All' && {
                        parent_validation_status: selectedStatusAnagrafica,
                        parent_survey_id: anagrafica,
                        parent_field_id: enteProponente,
                    }),
                    ...(selectedEnte !== 'All' && {parent_id: selectedEnte, parent_field_id: enteProponente}),
                    ...(selectedProgetto !== 'All' && {uoa_id: selectedProgetto}),
                },
            }),
        ];

        return await Promise.all(requests);
    };

    const getAnagraficaSubmission = async (ids: any[]): Promise<any[]> => {
        const requests = [
            await apiCall({
                url: `containers/${container}/submissions`,
                params: {
                    completed: 1,
                    survey_id: anagrafica,
                    additional_data: 'false',
                    uoa_ids: ids,
                },
            }),
        ];

        return await Promise.all(requests);
    };

    const getSurvey = async () => {
        try {
            setLoading(true);

            if (
                propostaProgettuale &&
                propostaTopDown &&
                propostaDispersione &&
                propostaRicerca &&
                propostaEcosistemiCulturali &&
                propostaDispersioneScolastica &&
                hashKey === hashKeyLocal
            ) {
                const responses = await getSubmission(rowsPerPage, page);

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    const data = responses[0].data.submissions;
                    setSubmissions(data);
                    setMeta(responses[0].data.meta);

                    await getAnagraficaSurvey(
                        data.map((submission: any) => submission.uoa_custom_field_ente_proponente_51_id_value)
                    );
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAllSurveys = async () => {
        let submissions: any[] = [];

        if (
            propostaProgettuale &&
            propostaTopDown &&
            propostaDispersione &&
            propostaRicerca &&
            propostaEcosistemiCulturali &&
            propostaDispersioneScolastica &&
            hashKey === hashKeyLocal
        ) {
            let page = 1;
            let meta = {
                last_page: 1,
            };

            do {
                const responses = await getSubmission(50, page);
                page++;

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    submissions = [...submissions, ...responses[0].data.submissions];
                    meta = responses[0].data.meta;
                }

                if (page > meta.last_page) {
                    break;
                }
            } while (true);
        }

        return submissions;
    };

    const getAnagraficaSurvey = async (ids: []) => {
        try {
            if (anagrafica && ids.length > 0) {
                const responses = await getAnagraficaSubmission([].concat(...ids));

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    setAnagraficaSubmissions(responses[0].data.submissions);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAllAnagraficaSurvey = async (ids: any[]): Promise<any[]> => {
        let submissions: any[] = [];

        if (anagrafica && ids.length > 0) {
            const uoaIds = [].concat(...ids);

            for (let i = 0; i < uoaIds.length; i += 20) {
                try {
                    const responses = await getAnagraficaSubmission(uoaIds.slice(i, i + 20));

                    if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                        submissions = [...submissions, responses[0].data.submissions];
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }

        return submissions;
    };

    useEffect(() => {
        getFilterData().then((_) => setIsReady(true));
    }, []);

    useEffect(() => {
        if (isReady) {
            getSurvey().then((_) => setLoading(false));
        }
    }, [
        selectedUoa,
        isReady,
        page,
        rowsPerPage,
        selectedStatusPropostaProgettuale,
        selectedStatusAnagrafica,
        selectedEnte,
        selectedProgetto,
    ]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };

    const handleUoaChanged = (value: any) => {
        setSelectedUoa(value);
        setPage(1);
        setRowsPerPage(10);
    };

    const handleStatusPropostaProgettualeChanged = (value: any) => {
        setSelectedStatusPropostaProgettuale(value);
        setPage(1);
        setRowsPerPage(10);
    };

    const handleStatusAnagraficaChanged = (value: any) => {
        setSelectedStatusAnagrafica(value);
        setPage(1);
        setRowsPerPage(10);
    };

    const handleEnteChanged = (value: any) => {
        setSelectedEnte(value);
        setPage(1);
        setRowsPerPage(10);
    };

    const handleProgettoChanged = (value: any) => {
        setSelectedProgetto(value);
        setPage(1);
        setRowsPerPage(10);
    };

    return (
        <div className='container space-y-8'>
            {!loading && (
                <>
                    <div className='filter space-x-4 flex'>
                        <CustomSelect
                            items={uoas}
                            value={selectedUoa}
                            onChange={(value: any) => handleUoaChanged(value)}
                            label='Bando'
                            className='uoa-select'
                            defaultVariant='All'
                        />
                        <CustomSelect
                            items={statuses}
                            value={selectedStatusAnagrafica}
                            onChange={(value: any) => handleStatusAnagraficaChanged(value)}
                            label='Status Anagrafica'
                            className='uoa-select'
                            defaultVariant='All'
                        />
                        <CustomSelect
                            items={statuses}
                            value={selectedStatusPropostaProgettuale}
                            onChange={(value: any) => handleStatusPropostaProgettualeChanged(value)}
                            label='Status della proposta'
                            className='uoa-select'
                            defaultVariant='All'
                        />
                        <CustomSelect
                            items={enteOptions}
                            value={selectedEnte}
                            onChange={(value: any) => handleEnteChanged(value)}
                            onInputChange={handeEnteAutocompleteInputChanged}
                            label='Ente'
                            className='uoa-select'
                            defaultVariant='All'
                            loading={enteOptionsLoading}
                            noOptionsText='Digita per ricerca...'
                        />
                        <CustomSelect
                            items={progettoOptions}
                            value={selectedProgetto}
                            onChange={(value: any) => handleProgettoChanged(value)}
                            onInputChange={handeProgettoAutocompleteInputChanged}
                            label='Progetto'
                            className='uoa-select'
                            defaultVariant='All'
                            loading={progettoOptionsLoading}
                            noOptionsText='Digita per ricerca...'
                        />
                    </div>

                    <div>
                        {submissions.length > 0 && (
                            <Table
                                statuses={statuses}
                                uoas={uoas}
                                submissions={submissions}
                                anagraficaSubmissions={anagraficaSubmissions}
                                meta={meta}
                                containerId={containerId}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                getAllSurveys={getAllSurveys}
                                getAllAnagraficaSurvey={(ids) => getAllAnagraficaSurvey(ids)}
                            />
                        )}
                        {submissions.length === 0 && <NoResults />}
                    </div>
                </>
            )}
            {loading && (
                <div className='loader-container'>
                    <Loader size={60} />
                </div>
            )}
        </div>
    );
};

export default CdpProposalGrid;
