import { Card, CardContent } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { useEffect, useRef, useState } from 'react';
import { IPavesiColumnsProps } from '../../utils/models';
import mapAnswers from '../../utils/mapAnswers';

interface IIstruzioneProps extends IPavesiColumnsProps {
  question: string;
}

const Istruzione = ({submissions, question}: IIstruzioneProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  const calculateSubmissions = () => {
    const answersMapper: any = {
      'Università': {},
      'Scuola Secondaria Superiore': {},
      'Scuola Secondaria Inferiore': {},
      'Scuola Primaria': {},
      'Sconosciuto': {},
      'Nessuno': {},
      empty: {}
    }

    mapAnswers(submissions, answersMapper, question);

    const dashboardOptions: any = {
      chart: {
        height: 400,
        type: 'column',
        style: {
          fontFamily: 'Rubik, sans-serif'
        },
        backgroundColor: '#fafafa'
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: ['INIZIO a.s. 2021/2022', 'INIZIO a.s. 2022/2023']
      },
      yAxis: {
        min: 0,
        stackLabels: {
          enabled: true,
        },
      },
      plotOptions: {
        column: {
          cursor: 'pointer',
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'Università',
          color: '#7ba9f7',
          data: [answersMapper['Università']['4'] || 0, answersMapper['Università']['25'] || 0]
        },
        {
          name: 'Scuola Secondaria Superiore',
          color: '#46bec6',
          data: [answersMapper['Scuola Secondaria Superiore']['4'] || 0, answersMapper['Scuola Secondaria Superiore']['25'] || 0]
        },
        {
          name: 'Scuola Secondaria Inferiore',
          color: '#ff6d01',
          data: [answersMapper['Scuola Secondaria Inferiore']['4'] || 0, answersMapper['Scuola Secondaria Inferiore']['25'] || 0]
        },
        {
          name: 'Scuola Primaria',
          color: '#34a754',
          data: [answersMapper['Scuola Primaria']['4'] || 0, answersMapper['Scuola Primaria']['25'] || 0]
        },
        {
          name: 'Sconosciuto',
          color: '#fcbc00',
          data: [answersMapper['Sconosciuto']['4'] || 0, answersMapper['Sconosciuto']['25'] || 0]
        },
        {
          name: 'Nessuno',
          color: '#ea4236',
          data: [answersMapper['Nessuno']['4'] || 0, answersMapper['Nessuno']['25'] || 0]
        },
        {
          name: 'Nessuna Risposta',
          color: '#4285f4',
          data: [answersMapper.empty['4'] || 0, answersMapper.empty['25'] || 0]
        },
      ]
    };

    setOptions(dashboardOptions);
  };

  useEffect(() => {
    calculateSubmissions();
  }, [submissions]);

  return (
    <Card className="chart-container">
      <CardContent>
        {
          options && (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          )
        }
      </CardContent>
    </Card>
  );
};

export default Istruzione;
