import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {percentageFormatter} from '../../helpers/formatters';

interface IEnrolledPerCountryProps {
    submissions: any[];
}

const EnrolledPerCountry = ({submissions}: IEnrolledPerCountryProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const values: string[] = submissions
            .map((submission: any) => submission.uoa_breakdown_mbas_3?.split(',')[0])
            .filter((value) => value);

        const uniqueValues = values.filter((value, index) => values.indexOf(value) === index).sort();

        const data = uniqueValues.map((value) => {
            const count = values.filter((val) => val === value).length;

            return {name: value === 'Camerun' ? 'Cameroon' : value, y: count};
        });

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Entrepreneurs enrolled per country',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default EnrolledPerCountry;
