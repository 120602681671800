import {sha256} from 'js-sha256';
import {useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import CustomSelect from '../../shared/CustomSelect';
import Genere from './beneficiaryCharts/Genere';
import AgeChart from './beneficiaryCharts/AgeChart';
import BeneficiaryCounter from './beneficiaryCharts/BeneficiaryCounter';
import OriginTable from './beneficiaryCharts/OriginTable';
import Progetti from './beneficiaryCharts/Progetti';
import GradoScolastico from './columnCharts/GradoScolastico';
import Precarita from './columnCharts/Precarita';
import Istruzione from './columnCharts/Istruzione';

import './styles.scss';

const PavesiHomepage = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const [loading, setLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const [genders, setGenders] = useState<any>();
    const [ages, setAges] = useState<any>();
    const [origins, setOrigins] = useState<any>();
    const [beneficiaryCount, setBeneficiaryCount] = useState<number>(0);
    const [projects, setProjects] = useState<any>([]);
    const [selectedProject, setSelectedProject] = useState<string>('All');
    const [selectedCheckpoint, setSelectedCheckpoint] = useState<any>('All');
    const [checkpoints, setCheckpoints] = useState<any>([]);
    const [levels, setLevels] = useState<{ id: number, name: string }[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);

    setUserToken( searchParams.get('token') || '')

    const getFilterData = async () => {
        const requests = [
            await apiCall({
                url: `containers/${container}/checkpoints`,
                params: {
                    container_id: 3,
                }
            }),
            await apiCall({
                url: `containers/${container}/breakdowns/5/levels`,
            }),
        ]

        const responses = await Promise.all(requests);

        setCheckpoints(responses[0]?.data?.checkpoints);
        setLevels(responses[1]?.data?.items);
    }

    const getSurvey = async () => {
        const hashKey = searchParams.get('key');
        const survey_id = searchParams.get('survey_id');
        const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_PAVESI_HOMEPAGE || '');
        const selected_levels = selectedProject === 'All' ? null : [selectedProject];

        try {
            if (survey_id && hashKey === hashKeyLocal) {
                setLoading(true);
                const requests = [
                    await apiCall({
                        url: `containers/${container}/fields/12/search`,
                        params: {
                            searched: {
                                selected_levels
                            }
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/fields/13/search`,
                        params: {
                            searched: {
                                selected_levels
                            }
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/fields/14/search`,
                        params: {
                            searched: {
                                selected_levels
                            }
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/uoa`,
                        params: {
                            container_id: 3,
                            searched: {
                                type_id: 2,
                                selected_levels
                            }
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id,
                            additional_data: false,
                            ...(selectedProject !== 'All') && {level_id: selectedProject},
                            ...(selectedCheckpoint !== 'All') && {checkpoint_id: selectedCheckpoint},
                        }
                    })
                ];

                const projectRequests = [
                    await apiCall({
                        url: `containers/${container}/breakdowns/5/search`,
                    })
                ]

                const responses = await Promise.all(requests);
                const projectResponses = await Promise.all(projectRequests);

                setGenders(responses[0].data.data);
                setAges(responses[1].data.data);
                setOrigins(responses[2].data.data);
                setBeneficiaryCount(responses[3].data.meta.total);
                setSubmissions(responses[4]?.data?.submissions);
                setProjects(projectResponses[0].data.data)
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getFilterData().then(_ => setIsReady(true));
    }, []);

    useEffect(() => {
        if (isReady) {
            getSurvey().then(_ => setLoading(false));
        }
    }, [selectedProject, selectedCheckpoint, isReady]);

    return (
        <div className="pavesi-homepage">
            {
                !loading && (
                    <>
                        <div className="filter space-x-4">
                            <CustomSelect
                                items={levels}
                                value={selectedProject}
                                onChange={(value: any) => setSelectedProject(value)}
                                label="Progetti"
                                className="project-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={checkpoints}
                                value={selectedCheckpoint}
                                onChange={(value: any) => setSelectedCheckpoint(value)}
                                label="Checkpoints"
                                className="project-select"
                                defaultVariant="All"
                            />
                        </div>

                        <div className="beneficiary-charts">
                            <div className="beneficiary-charts__item">
                                <div>
                                    <div className="section-title">Maschi / Femmine</div>
                                    {
                                        genders && <Genere data={genders}/>
                                    }
                                </div>
                                <div>
                                    <div className="section-title">Fascia d'età</div>
                                    {
                                        ages && <AgeChart data={ages}/>
                                    }
                                </div>
                                <div>
                                    <div className="section-title">Attività partecipate</div>
                                    {
                                        projects && <Progetti data={projects} selectedProject={selectedProject}/>
                                    }
                                </div>
                            </div>
                            <div className="beneficiary-charts__item">
                                <div>
                                    <div className="section-title">Beneficiari</div>
                                    {
                                        beneficiaryCount && <BeneficiaryCounter data={beneficiaryCount}/>
                                    }
                                </div>
                                {
                                    origins && <OriginTable data={origins} total={beneficiaryCount}/>
                                }
                            </div>
                        </div>

                        <div className="column-charts">
                            <div className="column-charts__row">
                                <div>
                                    <div className="section-title">Grado scolastico</div>
                                    <GradoScolastico submissions={submissions}/>
                                </div>
                                <div>
                                    <div className="section-title">Livello reddituale della famiglia</div>
                                    <Precarita submissions={submissions}/>
                                </div>
                            </div>
                            <div className="column-charts__row">
                                <div>
                                    <div className="section-title">Istruzione del Padre</div>
                                    <Istruzione submissions={submissions} question="question11"/>
                                </div>
                                <div>
                                    <div className="section-title">Istruzione della Madre</div>
                                    <Istruzione submissions={submissions} question="question16"/>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default PavesiHomepage;
