import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IAgrifoodSectorSectorProps {
    submissions: any[];
}

const AgrifoodSectorSector = ({submissions}: IAgrifoodSectorSectorProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [rows, setRows] = useState<[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    const calculateSubmissions = () => {
        let data: any = [];

        submissions.forEach((submission) => {
            for (let i = 0; i < 20; i++) {
                const key = `question1_${i}`;
                const value = submission[key];

                if (value === null) continue;

                const index = data.findIndex((item: any) => item.name === value);

                if (index === -1) {
                    data.push({name: value, y: 1});
                } else {
                    data[index].y += 1;
                }
            }
        });

        data = data.sort((a: any, b: any) => (b.name > a.name ? -1 : 1));

        setColumns([
            {field: 'name', headerName: 'Specialty', width: 200},
            {field: 'y', headerName: 'Submissions', width: 200},
        ]);
        setRows(data.map((row: any, index: any) => ({id: index, ...row})));

        const dashboardOptions: any = getDefaultPieFormatting('Specialty of the agrifood sector', data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <div className='row'>
            <div>
                <Card className='chart-container'>
                    <CardContent>
                        {options && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                            />
                        )}
                    </CardContent>
                </Card>
            </div>

            <div>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    hideFooterPagination={true}
                    hideFooter={true}
                />
            </div>
        </div>
    );
};

export default AgrifoodSectorSector;
