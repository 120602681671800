import * as Highcharts from 'highcharts';
import React, {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {IChartProps, IUoaValue} from '../utils/models';
import makeAverageFromAverage from '../utils/makeAverageFromAverage';
import {COLORS} from '../../../utils/constants';
import IconButton from "@mui/material/IconButton";
import Info from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const transformationsA = {
    "Sconosciuto": 0,
    "Scuola Primaria": 1.25,
    "Scuola Secondaria Inferiore": 2.5,
    "Scuola Secondaria Superiore": 3.75,
    "Università": 5.0,
    "Nessuno": 0,
};

const transformationsB = {
    "Precario": 1.25,
    "Basso": 2.5,
    "Medio": 3.75,
    "Alto": 5
};

const transformationsC = {
    "Sconosciuto": 0,
    "Disoccupato": 0,
    "Precario": 1.25,
    "Impiegato Pubblico": 2.5,
    "Impiegato Privato": 3.75,
    "Libero Professionista": 5,
    "Nessuno": 0,
    "other": 0,
};

const ProfiloFamiliare = ({submissions, uoas, selectedUoa, checkpoints}: IChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const processSubmission = (submission: any, line: IUoaValue[][]) => {
        const question11 = transformationsA[submission?.question11 as keyof typeof transformationsA]

        if (question11) {
            line[0].push({uoa: submission.uoa_id, value: question11});
        }

        const question12 = transformationsC[submission?.question12 as keyof typeof transformationsC]

        if (question12) {
            line[1].push({uoa: submission.uoa_id, value: question12});
        }

        const question14 = transformationsB[submission?.question14 as keyof typeof transformationsB]

        if (question14) {
            line[2].push({uoa: submission.uoa_id, value: question14});
        }

        const question17 = transformationsC[submission?.question17 as keyof typeof transformationsC]

        if (question17) {
            line[3].push({uoa: submission.uoa_id, value: question17});
        }

        const question16 = transformationsA[submission?.question16 as keyof typeof transformationsA]

        if (question16) {
            line[4].push({uoa: submission.uoa_id, value: question16});
        }
    };

    const calculateSubmissions = (submissionsArray: any[]) => {
        const line1: IUoaValue[][] = [[], [], [], [], []];
        const line2: IUoaValue[][] = [[], [], [], [], []];

        submissionsArray.forEach((submission: any) => {
            if (selectedUoa === 'All' || selectedUoa === submission.uoa_id) {
                if (submission.checkpoint_id === 4) {
                    processSubmission(submission, line1);
                } else if (submission.checkpoint_id === 25) {
                    processSubmission(submission, line2);
                }
            }
        });

        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: ['Istruzione del Padre', 'Occupazione del Padre', 'Livello di Reddito della Famiglia', 'Occupazione della Madre', 'Istruzione della Madre']
            },
            yAxis: {
                tickAmount: 6,
                min: 0,
                max: 5,
                labels: {
                    format: '{value:.2f}'
                },
            },
            series: [
                {
                    type: 'line',
                    name: checkpoints.find((checkpoint: any) => checkpoint.id === 4)?.name,
                    color: COLORS.blue,
                    data: makeAverageFromAverage(line1, selectedUoa, uoas),
                },
                {
                    type: 'line',
                    name: checkpoints.find((checkpoint: any) => checkpoint.id === 25)?.name,
                    color: COLORS.red,
                    data: makeAverageFromAverage(line2, selectedUoa, uoas),
                }
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions(submissions);
    }, [selectedUoa]);

    return (
        <div className="profilo-familiare">
            <div className="section-title">
                Profilo familiare
                <Tooltip
                    sx={{
                        marginLeft: '0.5rem'
                    }}
                    title={<div className="space-y-4">
                        <div className="px-4">
                            <div>Istruzione del Padre / Istruzione della Madre</div>
                            <li>Sconosciuto o nessuno = 0</li>
                            <li>Scuola Primaria = 1,25</li>
                            <li>Scuola Secondaria Inferiore = 2,5</li>
                            <li>Scuola Secondaria Superiore = 3,75</li>
                            <li>Università = 5</li>
                        </div>
                        <div className="px-4">
                            <div>Occupazione del Padre / Occupazione della Madre</div>
                            <li>Sconosciuto o disoccupato = 0</li>
                            <li>Precario, altro = 1,25</li>
                            <li>Impiegato Pubblico = 2,5</li>
                            <li>Impiegato Privato = 3,75</li>
                            <li>Libero Professionista = 5</li>
                        </div>

                        <div className="px-4">
                            <div>Livello di Reddito della Famiglia</div>
                            <li>Precario = 1,25</li>
                            <li>Basso = 2,5</li>
                            <li>Medio = 3,75</li>
                            <li>Alto = 5</li>
                        </div>
                    </div>}
                >
                    <IconButton>
                        <Info fontSize="small"/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                options && (
                    <Card>
                        <CardContent>
                            {
                                options && (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                        ref={chartComponentRef}
                                    />
                                )
                            }
                        </CardContent>
                    </Card>
                )
            }
        </div>
    );
};

export default ProfiloFamiliare;
