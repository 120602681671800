import AlreadySoldChart from './AlreadySoldChart';
import StartedSellingChart from './StartedSellingChart';

interface IBusinessProfileProps {
    baseline: any[];
    final: any[];
}

const ProductsNotInMarket = ({baseline, final}: IBusinessProfileProps) => {
    return (
        <div className='row'>
            <AlreadySoldChart baseline={baseline} />
            <StartedSellingChart
                baseline={baseline}
                final={final}
            />
        </div>
    );
};

export default ProductsNotInMarket;
