import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'
import {sha256} from 'js-sha256';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import CompetenzeEducative from './CompetenzeEducative';
import ProfiloFamiliare from './ProfiloFamiliare';
import RelazioniSociali from './RelazioniSociali';
import CustomSelect from '../../shared/CustomSelect';

import './styles.scss';

HighchartsMore(Highcharts);

const Pavesi = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const hashKey = searchParams.get('key');
    const surveyTutorID = searchParams.get('surveyTutorID');
    const surveyBeneficID = searchParams.get('surveyBeneficID');
    const checkpointId = searchParams.get('checkpoint_id');
    const containerId = searchParams.get('container_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_PAVESI || '');

    const [loading, setLoading] = useState(true);
    const [loadingFilterData, setLoadingFilterData] = useState(true);
    const [submissionsTutor, setSubmissionsTutor] = useState([]);
    const [submissionsBenefic, setSubmissionsBenefic] = useState([]);
    const [uoas, setUoas] = useState<{ id: number, name: string }[]>([]);
    const [yearField, setYearField] = useState<{ id: number, name: string }>({id: 0, name: ''});
    const [schoolYear, setSchoolYear] = useState<{ id: number, name: string }[]>([]);
    const [checkpoints, setCheckpoints] = useState<any[]>([]);
    const [selectedUoa, setSelectedUoa] = useState<string>('All');
    const [selectedCheckpoint, setSelectedCheckpoint] = useState<any>('All');
    const [levels, setLevels] = useState<{ id: number, name: string }[]>([]);
    const [selectedLevel, setSelectedLevel] = useState<any>('All');
    const [selectedYear, setSelectedYear] = useState<any>('All');

    setUserToken( searchParams.get('token') || '')

    const getFilterData = async () => {
        const requests = [
            await apiCall({
                url: `containers/${container}/checkpoints`,
                params: {
                    container_id: containerId,
                }
            }),
            await apiCall({
                url: `containers/${container}/breakdowns/5/levels`,
            }),
            await apiCall({
                url: `containers/${container}/fields/by-key`,
                params: {
                    container_id: containerId,
                    field_key: 'anno-scolastico',
                }
            }),
        ]

        const responses = await Promise.all(requests);

        setCheckpoints(responses[0]?.data?.checkpoints);
        setLevels(responses[1]?.data?.items);

        if (responses.length > 2 && responses[2].data.hasOwnProperty('custom_field')) {
            const customField = responses[2].data.custom_field

            setYearField({
                id: customField.id,
                name: customField.name,
            })
            setSchoolYear(
                customField.options.map((option: any) => ({
                    name: option.name,
                    id: option.id,
                }))
            )
        }
    }

    const getSurvey = async () => {
        let uoasArray: { id: number, name: string }[] = [];
        let selectedField: any = null;

        if (yearField && selectedYear !== 'All') {
            selectedField = {[`field_${yearField.id}`]: selectedYear}
        }

        try {
            if (surveyTutorID && surveyBeneficID && hashKey === hashKeyLocal) {
                const submissionsMap = {
                    [surveyTutorID]: setSubmissionsTutor,
                    [surveyBeneficID]: setSubmissionsBenefic,
                };

                setLoading(true);

                const requests = [surveyTutorID, surveyBeneficID].map((survey_id) => (
                    apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id,
                            additional_data: false,
                            ...(selectedCheckpoint !== 'All') && {checkpoint_id: selectedCheckpoint},
                            ...(selectedLevel !== 'All') && {level_id: selectedLevel},
                            custom_fields: {
                                ...selectedField
                            },
                        }
                    })
                ));

                const responses = await Promise.all(requests);

                responses.forEach((response) => {
                    const responseSubmissions = response?.data?.submissions;
                    responseSubmissions.forEach((submission: any) => {
                        if (!uoasArray.some(uoa => uoa.id === submission.uoa_id)) {
                            uoasArray.push({
                                name: submission.uoa_name,
                                id: submission.uoa_id,
                            });
                        }
                    });

                    if (submissionsMap.hasOwnProperty(response.config.params?.survey_id)) {
                        submissionsMap[response.config.params?.survey_id](responseSubmissions)
                    }
                });

                setUoas(
                    uoasArray
                        .sort((a, b) => {
                            if (a.name < b.name)
                                return -1;
                            if (a.name > b.name)
                                return 1;
                            return 0;
                        }));
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getFilterData().then(r => setLoadingFilterData(false));
    }, []);

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, [selectedCheckpoint, selectedLevel, selectedYear]);

    return (
        <div className="pavesi">
            {
                (!loading && !loadingFilterData) && (
                    <>
                        <div className="filter space-x-4">
                            <CustomSelect
                                items={uoas}
                                value={selectedUoa}
                                onChange={(value: any) => setSelectedUoa(value)}
                                label="UOA"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={levels}
                                value={selectedLevel}
                                onChange={(value: any) => setSelectedLevel(value)}
                                label="Progetti"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={checkpoints}
                                value={selectedCheckpoint}
                                onChange={(value: any) => setSelectedCheckpoint(value)}
                                label="Checkpoints"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={schoolYear}
                                value={selectedYear}
                                onChange={(value: any) => setSelectedYear(value)}
                                label={yearField.name}
                                className="uoa-select"
                                defaultVariant="All"
                            />
                        </div>
                        <CompetenzeEducative
                            checkpoints={checkpoints}
                            submissions={submissionsTutor}
                            uoas={uoas}
                            selectedUoa={selectedUoa}
                            checkpointId={checkpointId}
                        />
                        <div className="charts-row">
                            <ProfiloFamiliare
                                checkpoints={checkpoints}
                                submissions={submissionsBenefic}
                                uoas={uoas}
                                selectedUoa={selectedUoa}
                            />
                            <RelazioniSociali
                                checkpoints={checkpoints}
                                submissions={submissionsTutor}
                                uoas={uoas}
                                selectedUoa={selectedUoa}
                            />
                        </div>

                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default Pavesi;
