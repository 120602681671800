import * as React from 'react';
import {Typography} from '@mui/material';
import ProductsNotInMarket from './ProductsNotInMarket';
import ProductsInMarket from './ProductsInMarket';
import BusinessModelImproved from './BusinessModelImproved';
import BookkeepingImprovement from './BookkeepingImprovement';
import ExternalFunding from './ExternalFunding';
import FundsKind from './FundsKind';
import SizeOfFunds from './SizeOfFunds';
import NewSalesChannels from './NewSalesChannels';
import StartedSell from './StartedSell';
import TypeOfChannels from './TypeOfChannels';
import IncreasedCustomerNetwork from './IncreasedCustomerNetwork';
import BusinessImpact from './BusinessImpact';
import TypeOfMeasures from './TypeOfMeasures';
import getValidSubmissions from '../helpers/getValidSubmissions';

interface IMidTermProps {
    final: any[];
    baseline: any[];
}

const MidTerm = ({final, baseline}: IMidTermProps) => {
    const {filteredBaseline} = getValidSubmissions(baseline, final);

    return (
        <div>
            <Typography variant='h5'>BUSINESS PERFORMANCE OF ESTABLISHED BUSINESSES</Typography>
            <br />
            <Typography variant='h6'>Market entry</Typography>
            <ProductsNotInMarket
                baseline={baseline}
                final={final}
            />
            <br />
            <ProductsInMarket
                final={final}
                baseline={baseline}
            />
            <br />
            <Typography variant='h5'>BUSINESS MODEL</Typography>
            <br />
            <BusinessModelImproved final={final} />
            <br />
            <Typography variant='h5'>Bookkeeping improvement</Typography>
            <br />
            <BookkeepingImprovement
                baseline={baseline}
                final={final}
            />
            <br />
            <div className='row'>
                <div>
                    <NewSalesChannels final={final} />
                </div>
                <div>
                    <TypeOfChannels final={final} />
                </div>
            </div>
            <br />
            <Typography variant='h5'>BUSINESS PERFORMANCE OF NEW BUSINESSES (STARTED AS A BUSINESS IDEA)</Typography>
            <br />
            <Typography variant='h6'>Market entry</Typography>
            <StartedSell
                baseline={filteredBaseline}
                final={final}
            />
            <br />
            <Typography variant='h5'>BUSINESS PERFORMANCE OF BOTH ESTABLISHED AND NEW BUSINESSES</Typography>
            <Typography variant='h6'>Funding raised</Typography>
            <div className='row'>
                <div>
                    <ExternalFunding final={final} />
                </div>
                <div>
                    <FundsKind final={final} />
                </div>
            </div>
            <SizeOfFunds final={final} />
            <br />
            <IncreasedCustomerNetwork final={final} />
            <br />
            <BusinessImpact final={final} />
            <br />
            <TypeOfMeasures final={final} />
        </div>
    );
};

export default MidTerm;
