import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from "@mui/material/TablePagination";

import './styles.scss';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(243 244 246)',
        color: theme.palette.common.black,
        borderBottom: '1px solid',
        borderColor: 'rgb(156 163 175)',
        whiteSpace: 'nowrap',
        borderLeft: '1px solid',
        fontWeight: 700,
        fontSize: 16,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        borderLeft: '1px solid',
        borderColor: 'rgb(156 163 175)'
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&': {
        backgroundColor: '#fff',
        borderBottom: '1px solid',
        borderColor: 'rgb(156 163 175)'
    },
}));

const StyledTable = styled(Table)(() => ({
    '&': {
        border: '1px solid',
        borderLeft: '0',
        borderColor: 'rgb(156 163 175)'
    },
}));

interface ITableProps {
    columns: string[];
    rows: any[];
    numberRows: number[];
    meta: {
        current_page: number,
        total: number,
        per_page: number,
    },
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

export default function CustomTable({rows, columns, numberRows, meta, handleChangePage, handleChangeRowsPerPage}: ITableProps) {
    return (
        <Paper className="api-table-container">
            <TableContainer>
                <StyledTable aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column, colIndex) => (
                                    <StyledTableCell key={colIndex}>{column}</StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        row.map((rowColumn: any, rowIndex: number) => (
                                            <StyledTableCell key={rowIndex}>
                                                <div
                                                    title={rowColumn}
                                                    className={
                                                        'text-container '
                                                        + (numberRows.includes(rowIndex) ? 'text-right ' : '')
                                                        + (row.length > 4 ? 'w-200' : '')
                                                    }
                                                >
                                                    {rowColumn}
                                                </div>
                                            </StyledTableCell>
                                        ))
                                    }
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </StyledTable>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={meta.total}
                rowsPerPage={meta.per_page}
                page={meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}