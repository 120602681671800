import * as React from 'react';
import {Typography} from '@mui/material';
import getValidSubmissions from '../helpers/getValidSubmissions';
import EntrepreneursIncrease from './EntrepreneursIncrease';
import IncreasedSkills from './IncreasedSkills';
import ResultsAchieved from './ResultsAchieved';
import TotalRespondents from './TotalRespondents';

interface IShortTermProps {
    baseline: any[];
    final: any[];
}

const ShortTerm = ({baseline, final}: IShortTermProps) => {
    const {filteredBaseline, filteredFinal} = getValidSubmissions(baseline, final);

    return (
        <div>
            <TotalRespondents total={filteredFinal.length} />
            <br />
            <Typography variant='h5'>Increased entrepreneurial mindset</Typography>
            <Typography variant='h6'>% of participants who increased their entrepreneurial mindset</Typography>
            <br />
            <EntrepreneursIncrease
                baseline={filteredBaseline}
                final={filteredFinal}
            />
            <br />
            <Typography variant='h5'>Increased entrepreneurial skills</Typography>
            <br />
            <IncreasedSkills
                baseline={filteredBaseline}
                final={filteredFinal}
            />
            <br />
            <ResultsAchieved submissions={filteredFinal} />
        </div>
    );
};

export default ShortTerm;
