import {useEffect, useState} from 'react';
import {IFoodBanksProps} from "../../utils/models";
import PieChart from "../../PieChart";

const Source = ({submissions}: IFoodBanksProps) => {
    const [series, setSeries] = useState<any[]>();

    const calculateSubmissions = () => {
        let community = 0, employees = 0, volunteers = 0;

        submissions.forEach((submission) => {
            community = community + (Number(submission.community_service) || 0)
            employees = employees + (Number(submission.employees) || 0)
            volunteers = volunteers + (Number(submission.regular_volunteers) || 0)
        })

        setSeries([{
            name: 'Sources',
            size: "100%",
            innerSize: '50%',
            data: [
                {
                    name: 'Community service',
                    y: community,
                    color: '#8a5eb0'
                },
                {
                    name: 'Employees',
                    y: employees,
                    color: '#ed8535'
                },
                {
                    name: 'Regular volunteers',
                    y: volunteers,
                    color: '#ef8c8c'
                },
            ],
        }]);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <div>
            {
                series && (
                    <PieChart
                        title="Coworkes pie chart"
                        fractionDigits={2}
                        series={series}
                    />
                )
            }
        </div>
    );
}

export default Source;