import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IStartedSellingChartProps {
    baseline: any[];
    final: any[];
}

const StartedSellingChartChart = ({baseline, final}: IStartedSellingChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const values = baseline.filter((value) => value.product_sold_t0 === 'No');
        const uoaIds = values.map((value) => value.uoa_id);
        const finalValues = final
            .filter((value) => uoaIds.includes(value.uoa_id))
            .map((value) => value.idea_product_sold_t1)
            .filter((value) => value !== null && value !== '');

        let data: any = [];

        finalValues.forEach((value: any) => {
            const index = data.findIndex((item: any) => item.name === value);

            if (index === -1) {
                data.push({name: value, y: 1});
            } else {
                data[index].y += 1;
            }
        });

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));

        const dashboardOptions: any = getDefaultPieFormatting(
            '% of companies that did not have the product in the market and started to sell the product',
            data
        );

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default StartedSellingChartChart;
